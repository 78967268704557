<template>
	<el-container>
		<el-main>
			<el-card shadow="never">
				<el-row :gutter="20">
					<el-col :span="18" :xs="24"><el-input placeholder="请输入用户id" v-model="input" clearable></el-input></el-col>
					<el-col :span="6" :xs="24"><el-button type="primary" @click="deleteBtn">删除</el-button></el-col>
				</el-row>
			</el-card>
		</el-main>
	</el-container>
</template>

<script>
export default {
	name: 'deleteDevice',
	data() {
		return {
			input: '',
			proId: ''
		};
	},
	created() {
		this.proId = this.$TOOL.data.get('DATA_SELECTEED');
	},
	watch: {
		'$store.state.dataSelected'(newval) {
			this.proId = newval;
		}
	},
	methods: {
		deleteBtn() {
			this.$HTTP.post(
				'userInfo/deleteUser',
				{
					id: this.input,
					productId: this.proId
				},
				response => {
					if (response.status >= 200 && response.status < 300) {
						if (response.data.code == 0) {
							this.$message.success(response.data.msg);
						} else {
							this.$message.error(response.data.msg);
						}
					} else {
						console.log(response.message);
					}
				}
			);
		}
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
